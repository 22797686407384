export default function useSystemPages() {
  const systemPages = computed<SystemPage[]>(() => {
    return [
      { id: 1, title: 'Vacaturebank', path: '/vacancies', identifier: 'vacancy-search' },
      { id: 2, title: 'Organisatie registereren', path: '/organizations/signup', identifier: 'organization-signup' },
      { id: 3, title: 'Inloggen', path: '/inloggen', identifier: 'login' },
      { id: 4, title: 'Wachtwoord vergeten', path: '/auth/recover', identifier: 'password-recovery' },
    ]
  })

  const findSystemPageByTitle = (title: string) => {
    return systemPages.value.find(page => page.title == title)
  }

  const findSystemPageById = (id: id) => {
    return systemPages.value.find(page => page.id == id)
  }

  const findSystemPageByIdentifier = (identifier: string) => {
    return systemPages.value.find(page => page.identifier == identifier)
  }

  return {
    systemPages,
    findSystemPageByTitle,
    findSystemPageById,
    findSystemPageByIdentifier,
  }
}
